import { Box, Button, Typography } from "@material-ui/core";
import logo from '../../assets/Primary-Connect-Plus-Logo.svg';
import animation from '../../assets/notification-img.svg'
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { AlertContext } from "../../contexts/alert-context";

const unsubscribeUrl = process.env.REACT_APP_WEBSITE_URL;

interface NotificationDetails {
    notificationId: number,
    userid: string,
    guid: string,
    notificationPrefMaster: {
        notificationMilestones: string,
        mandatory: boolean
    }
}

const Unsubscribe = () => {
    const { alert } = useContext(AlertContext);
    const { guid } = useParams<{ guid: string }>();
    const [unsubscribed, setUnsubscribed] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    // const [error, setError] = useState<boolean>(false);
    const [data, setData] = useState<NotificationDetails | undefined>(undefined);


    useEffect(() => {
        setLoading(true);
        fetch(`${unsubscribeUrl}/getNotificationDetailsForUnsubscribe/?&guid=${guid}`)
            .then((response) => response.json())
            .then((res: any) => {
                console.log(res)
                setLoading(false);
                setData(res);
            })
            .catch(err => { 
                setLoading(false);
                // setError(true);
                // Invalid URL
                // history.push('/not-found');
            });
       
    }, [guid]);


    const unSubscribeUser = () => {
        setSaving(true)
        fetch(`${unsubscribeUrl}/unsubscribeUserFromEmail`, { method: 'POST', body: JSON.stringify({ guid }) })
        .then(async (res: any) =>  {
            setSaving(false)
            const text = await res.text();
            if (text === 'True') {
                setUnsubscribed(true)
            } else {
                alert({ message: "Something Went Wrong", severity: 'error'})
            }
        }).catch(() => {
            setSaving(false)
        })
    }

    return (
        <Box p={4} height="100vh" boxSizing="border-box">
            <Box><a href="/"><img src={logo} alt="logo" width="148px" /></a></Box>
            {!loading ? (
                <>
                    {data?.userid? (
                        <>
                            {unsubscribed ? (
                                <Box display="flex" justifyContent="center" height="calc(100% - 100px)">
                                    <Box maxWidth="700px" overflow="auto" pt={6} display="flex" flexDirection="column" justifyContent="space-between">
                                        <Box display="flex" flexDirection="column" alignItems="center">
                                            <Box pb={2}><Typography variant="h1" align="center" >You are unsubscribed from this email</Typography></Box>
                                            <Typography variant="body1"  align="center"  style={{ wordWrap: "break-word" }} ><b>{data?.userid}</b> has been successfully removed from the: <b>“{data?.notificationPrefMaster?.notificationMilestones}”</b> email notification.</Typography>
                                            <Box p={2} />
                                            <Box display="flex" p={5} justifyContent="center" width="530px" maxWidth="100%" boxSizing="border-box" maxHeight="35vh">
                                                <img src={animation} width="100%" alt="" />
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Typography variant="body2"  align="center" >If you have questions please contact our Customer Service Team on 1800 PRIMARY</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            ) : (
                                <Box display="flex" justifyContent="center" height="calc(100% - 100px)">
                                    <Box maxWidth="700px" overflow="auto"  pt={6} display="flex" flexDirection="column" justifyContent="space-between">
                                        <Box>
                                            <Box pb={2}><Typography variant="h1" align="center"  style={{ wordWrap: "break-word" }} >You are about to unsubscribe this email for {data?.userid}</Typography></Box>
                                            <Typography variant="body1"  align="center" style={{ wordWrap: "break-word" }} >By clicking the button below, <b>{data?.userid}</b> will no longer receive the: <b>“{data?.notificationPrefMaster?.notificationMilestones}”</b> email notification.</Typography>
                                            <Box pt={4} display="flex" justifyContent="center">
                                                <Button color="primary" variant="contained" disabled={saving} onClick={unSubscribeUser} data-testid="unsubscribe-button">Unsubscribe</Button>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Typography variant="body2"  align="center" >If you have questions please contact our Customer Service Team on 1800 PRIMARY</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                        </>
                    ) : (
                        <Box display="flex" justifyContent="center" height="calc(100% - 100px)">
                            <Box maxWidth="700px" pt={6} overflow="auto"  display="flex" flexDirection="column" justifyContent="space-between">
                                <Box display="flex" flexDirection="column" alignItems="center">
                                    <Box pb={2}><Typography variant="h1" align="center" >You are already unsubscribed from this email</Typography></Box>
                                    <Box display="flex" p={5} justifyContent="center" width="530px" maxWidth="100%"  boxSizing="border-box" maxHeight="35vh">
                                        <img src={animation} width="100%" alt="" />
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography variant="body2"  align="center" >If you have questions please contact our Customer Service Team on 1800 PRIMARY</Typography>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </>
            ): ''}
            
        </Box>
    )
}

export default Unsubscribe;